/**
 * Author : Elia Contini - elia.contini.page
 * Created: 22 Mar 2020
 *
 * Information about post author
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./bio.css"
import EliaContini from "./../images/elia_contini.webp"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div className="bio">
      <div className="bio-figure">
        <img
        className="bio-image"
          src={EliaContini}
          height="100"
          width="100"
          alt={"A photo of " + author.name}
        />
      </div>
      <div className="bio-author">
        <div className="bio-writtenBy">Written by</div>
        <div className="bio-name">{author.name}</div>
        <div className="bio-summary">{author.summary}</div>
      </div>
    </div>
  )
}

export default Bio
