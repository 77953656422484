/**
 * Author : Elia Contini - elia.contini.page
 * Created: 04 Jul 2024
 *
 * Buy Me A Coffee button
 */

import React from "react";

import "./BuyMeACoffee.css";

const BuyMeACoffee = () => {
   return (
      <div className="BuyMeACoffee">
         Did you find this post useful? What about{" "}
         <a
            href="https://www.buymeacoffee.com/elia.contini"
            rel="noreferrer"
            target="_blank"
         >
            <img
               src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
               alt="Buy Me A Coffee"
               height={60}
               width={217}
            />
         </a>
      </div>
   );
};

export default BuyMeACoffee;
