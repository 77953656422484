/**
 * Author : Elia Contini - elia.contini.page
 * Created: 21 Mar 2020
 *
 * Blog post template
 */

import React from "react";
import { Link, graphql } from "gatsby";

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";

import Bio from "../components/bio";
import BuyMeACoffee from "../components/BuyMeACoffee";
import Layout from "../components/layout";
import SEO from "../components/seo";

const BlogPostTemplate = ({ data, pageContext, location }) => {
   const post = data.markdownRemark;
   const siteTitle = data.site.siteMetadata.title;
   const { previous, next } = pageContext;

   deckDeckGoHighlightElement();

   return (
      <Layout location={location} title={siteTitle}>
         <SEO
            blogPostMeta={post.frontmatter}
            title={post.frontmatter.title}
            description={post.frontmatter.description}
            location={location}
         />
         <article className="blogPost">
            <header className="blogPost-header">
               <h1 className="blogPost-title">{post.frontmatter.title}</h1>
               <small className="blogPost-date">{post.frontmatter.date}</small>
            </header>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
            <hr />
            <BuyMeACoffee />
            <hr />
            <footer>
               <Bio />
            </footer>
         </article>

         <nav className="navigationPost">
            <ul className="navigationPost-previousNext">
               {previous ? (
                  <li className="navigationPost-prev">
                     <p>
                        Previous post
                        <br />
                        <Link to={previous.fields.slug} rel="prev">
                           {previous.frontmatter.title}
                        </Link>
                     </p>
                  </li>
               ) : (
                  <li className="navigationPost-prev"></li>
               )}

               {next ? (
                  <li className="navigationPost-next">
                     <p>
                        Next post
                        <br />
                        <Link to={next.fields.slug} rel="next">
                           {next.frontmatter.title}
                        </Link>
                     </p>
                  </li>
               ) : (
                  <li className="navigationPost-next"></li>
               )}
            </ul>
         </nav>
      </Layout>
   );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
   query BlogPostBySlug($slug: String!) {
      site {
         siteMetadata {
            title
         }
      }
      markdownRemark(fields: { slug: { eq: $slug } }) {
         id
         excerpt(pruneLength: 160)
         html
         frontmatter {
            title
            card_image
            card_image_alt
            date(formatString: "MMMM DD, YYYY")
            description
         }
      }
   }
`;
